import NodeCache from "node-cache";

const nodeCache = new NodeCache({ stdTTL: 600, checkperiod: 600 });

export const get = <T = unknown>(path: string): { date: Date; data: T } | undefined => {
  return nodeCache.get(path);
};

export const mget = <T = unknown>(paths: string[]): { [key: string]: { date: Date; data: T } } | undefined => {
  return nodeCache.mget(paths);
};

export const set = (path: string, data: unknown): void => {
  nodeCache.set(path, { date: new Date(), data });
};
