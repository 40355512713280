import { LOCALES } from "@configs/translations";
import { DESTINATION_BY_CODE, TAppStaticPath } from "@utils/app-routes";

/**
 * Returns path to client side to use for app routing with provided admin static page routes.
 */
export const getStaticRoutePaths = (staticRoutes, locale?: string): TAppStaticPath => {
  const routes = staticRoutes.filter((dataItem) => !dataItem.locale || dataItem.locale === locale);
  const entries = Object.entries(DESTINATION_BY_CODE);

  const finalRoutes = entries.reduce((acc, dataValue) => {
    const key = `${dataValue[0]}Path` as keyof TAppStaticPath;

    const languagePaths = routes.find((route) => route.code === dataValue[0])?.languagePaths;
    const value = languagePaths?.find((i) => i.language === LOCALES[locale])?.path;

    acc[key] = value ? `/${value}` : dataValue[1].path;

    return acc;
  }, {} as TAppStaticPath);

  return finalRoutes;
};
