
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React from "react";
import { Button, VStack, Box, useBreakpointValue, Container, Link as ChakraLink } from "@chakra-ui/react";
import * as Sentry from "@sentry/nextjs";
import { NextPage } from "next";
import useTranslation from "next-translate/useTranslation";
import NextErrorComponent from "next/error";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { Heading } from "@components/Heading/Heading";
import { withErrorInitialProps } from "@middlewares/error";
type TErrorPageProps = {
    statusCode: number;
};
const CustomErrorComponent: NextPage<TErrorPageProps> = (props) => {
    const { t } = useTranslation();
    const { locale } = useRouter();
    return (<Container maxW="container.md">
      <VStack spacing="8" py="20">
        <Box position="relative" boxSize={useBreakpointValue({ base: "160px", lg: "384px" })}>
          <Image src="/assets/obdeleven/mrcan_400.svg" alt="404 illustration" priority layout="fill"/>
        </Box>

        <Heading as="h2" textAlign="center">
          {t(`common:page-${props.statusCode}`)}
        </Heading>
        <ChakraLink as={Link} href="/" locale={locale}>
          <Button>{t("common:return-home")}</Button>
        </ChakraLink>
      </VStack>
    </Container>);
};
CustomErrorComponent.getInitialProps = withErrorInitialProps(async (contextData) => {
    await Sentry.captureUnderscoreErrorException(contextData);
    const props = NextErrorComponent.getInitialProps(contextData);
    return {
        ...props,
    };
});
const __Next_Translate__Page__194fa5eb76c__ = CustomErrorComponent;

    export default __appWithI18n(__Next_Translate__Page__194fa5eb76c__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      
    });
  