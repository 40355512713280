import { NextPageContext } from "next";

import { TGlobalData, withGlobalData } from "./globalData";

type TCallbackProps = (contextData: NextPageContext) => Promise<any>;

type TCallbackResults = Promise<
  {
    statusCode: number;
  } & TGlobalData
>;

export const withErrorInitialProps = (callback: TCallbackProps) => {
  return async (contextData: NextPageContext): TCallbackResults => {
    const results = await callback(contextData);
    const globalData = await withGlobalData({
      isStatic: false,
      context: contextData,
    });

    return {
      ...results,
      ...globalData,
    };
  };
};
