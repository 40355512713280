/* eslint-disable @typescript-eslint/naming-convention */
import { GetServerSidePropsContext, NextPageContext } from "next";
import { getSession } from "next-auth/react";

import { TLocalization } from "@modules/Localization/types";
import { getXLocalizationHeader, parseJSONCookie } from "@modules/Localization/utils";
import api from "@utils/api";
import { API_ROUTES } from "@utils/api-routes";
import { APP_ROUTES } from "@utils/app-routes";
import { get, set } from "@utils/cache/mem";
import { logger } from "@utils/logger/server";
import { TCountry } from "@utils/types";

import { BASE_PUBLIC_BACKEND_URL, COOKIE_NAME } from "./constants";

export type TRequest = GetServerSidePropsContext["req"];
export type TResponse = GetServerSidePropsContext["res"] | NextPageContext["res"];

function isServerSidePropsReq(req: TRequest): req is GetServerSidePropsContext["req"] {
  return !!req?.headers.cookie;
}

const getServerSideHeaders = async (
  req: TRequest,
  res: TResponse,
  withAuthorization: boolean
): Promise<HeadersInit> => {
  const authKey = process.env.NEXT_PUBLIC_BACK_END_X_AUTH_KEY;

  const headers: {
    Authorization?: string;
    "X-Localization"?: string;
  } = {};

  if (isServerSidePropsReq(req) && withAuthorization) {
    const session = await getSession({ req });

    if (session?.accessToken) {
      headers.Authorization = `Bearer ${session.accessToken}`;
    }
  }

  const localization = parseJSONCookie(req?.cookies[COOKIE_NAME.USER_LOCALIZATION]) as TLocalization;

  if (localization) {
    headers["X-Localization"] = getXLocalizationHeader(localization);
  }

  return {
    ...(authKey
      ? {
          "X-Auth-Key": authKey,
        }
      : {}),
    ...headers,
  };
};

type TFetchFromServerSideParams = {
  context: GetServerSidePropsContext;
  path: string;
  baseUrl?: string;
  method?: "POST" | "GET" | "PUT" | "DELETE";
  cache?: boolean;
  /**
   * Apply `Bearer {sessionToken}` to header.
   *
   * @default false
   */
  withAuthorize?: boolean;
};
export const fetchFromServerSide = async <T>({
  path,
  context,
  method = "GET",
  withAuthorize = false,
  baseUrl = BASE_PUBLIC_BACKEND_URL,
  cache = false,
}: TFetchFromServerSideParams): Promise<T> => {
  const { req, res } = context;

  if (!req) {
    return api.request({ url: path, method });
  }

  const headers = await getServerSideHeaders(req, res, withAuthorize);
  const startTime = new Date().getTime();
  const cachePath = `${headers["X-Localization"] || "g"}-${method}-${path}`;

  if (cache) {
    const data = get<T>(cachePath);

    if (data) {
      return data.data;
    }
  }

  return fetch(`${baseUrl}${path}`, {
    method,
    headers,
    cache: "force-cache",
  })
    .then((response) => {
      if (response.status >= 400) {
        return Promise.reject(response);
      }

      return response;
    })
    .then(async (res) => {
      logger.debug(`API request (fetchFromServerSide): ${baseUrl}${path}`);
      logger.debug(`Took: ${new Date().getTime() - startTime}ms`);

      if (!res.ok) {
        throw new Error(res.statusText, {
          cause: {
            response: res,
            status: res.status,
          },
        });
      }

      const data = await res.json();

      set(cachePath, data);

      return data;
    });
};

type TAuthErrorResponse = { redirect: { destination: string; permanent: boolean } };

export const handleAuthError =
  (callback: (error: Response) => any) =>
  (error: Response): TAuthErrorResponse => {
    if (error.status === 404) {
      return {
        redirect: {
          permanent: false,
          destination: APP_ROUTES.signOut,
        },
      };
    }

    return callback(error);
  };

export const fetchCountries = (context: GetServerSidePropsContext): Promise<TCountry[]> =>
  fetchFromServerSide<TCountry[]>({
    context,
    path: API_ROUTES.countries,
    cache: true,
  }).catch(() => []);
