/* eslint-disable @typescript-eslint/naming-convention */
import axios from "axios";
import { GetServerSidePropsContext, GetStaticPropsContext } from "next";

import { IBlock, IPage, PageType, ResourceCode } from "@features/cms/types";
import { getStaticRoutePaths } from "@modules/Rewrites/utils";
import { API_ROUTES } from "@utils/api-routes";
import { TAppStaticPath } from "@utils/app-routes";
import { BASE_INTERNAL_URL, BASE_URL } from "@utils/axios/constants";
import { CONTENT_BLOCK_CODE } from "@utils/constants";
import { fetchFromServerSide } from "@utils/fetch-utils";
import { logger } from "@utils/logger/server";
import { getLocaleCodeByLanguage } from "@utils/translations";
import { TCountry } from "@utils/types";
import { TConfig } from "features/config/types";

const instance = axios.create({
  baseURL: process.env.NEXT_STAGE === "build" ? BASE_URL : BASE_INTERNAL_URL,
  timeout: 10000,
  headers: {
    "X-Auth-Key": process.env.NEXT_PUBLIC_BACK_END_X_AUTH_KEY || "",
  },
});

export interface IAppData {
  footerNavLinks: TNavigationLink[];
  headerNavLinks: TNavigationLink[];
  socialLinks: TBadge[];
  appProviders: TBadge[];
  paymentProviders: TBadge[];
  languages: TLanguage[];
  config: TConfig;
  userNavLinks: TNavigationLink[];
  affiliatesBlock?: IBlock;
  appStaticPath: TAppStaticPath;
  countries?: TCountry[];
  page?: IPage;
  pageType?: PageType;
  hideHeader?: boolean;
  hideFooter?: boolean;
}

export type TGlobalData = {
  config?: TConfig;
} & IAppData;

type TWithGlobalDataProps = {
  isStatic?: boolean;
  context?: GetServerSidePropsContext | GetStaticPropsContext;
  userLocale?: string;
};

const catchResults = (error) => {
  logger.error(error);

  // Throw error to show maintenance page
  if (error?.cause?.status === 503) {
    throw new Error(error.message);
  }

  return [];
};

/**
 * All required data for the global context
 * should be loaded here. It will be available in all pages in pageProps;
 */
export const withGlobalData = async ({ isStatic = false, context }: TWithGlobalDataProps): Promise<TGlobalData> => {
  const api = async (props, context) => {
    if (isStatic) {
      return instance({
        ...props,
        headers: {
          ...(props?.headers || {}),
          "X-Localization": `${context?.locale?.toUpperCase()},${getLocaleCodeByLanguage(context?.locale)}`,
        },
      }).then((res) => {
        return res.data;
      });
    }

    return fetchFromServerSide({
      path: props.url,
      context,
      cache: true,
    });
  };

  const footerNavLinksPromise = api(
    {
      url: "/section-menu/footer",
    },
    context
  ).catch(catchResults);

  const headerNavLinksPromise = api(
    {
      url: "/section-menu/header",
    },
    context
  ).catch(catchResults);

  const socialLinksPromise = api(
    {
      url: "/section-badge/social-links",
    },
    context
  ).catch(catchResults);

  const appProvidersPromise = api(
    {
      url: "/section-badge/app-download-links",
    },
    context
  ).catch(catchResults);

  const paymentProvidersPromise = api(
    {
      url: "/section-badge/payment-methods",
    },
    context
  ).catch(catchResults);

  const languagesPromise = api(
    {
      url: "/locales/all",
    },
    context
  ).catch(catchResults);

  const userNavLinksPromise = api(
    {
      url: "/section-menu/user",
    },
    context
  ).catch(catchResults);

  const affiliatesBlockPromise = api(
    {
      url: API_ROUTES.getBlock(CONTENT_BLOCK_CODE.AFFILIATES),
    },
    context
  ).catch(() => null);

  const configPromise = api(
    {
      url: "/site-settings",
    },
    context
  ).catch(catchResults);

  const staticRoutePathsPromise = api(
    {
      url: API_ROUTES.staticPages,
    },
    context
  ).catch(() => null);

  const [
    footerNavLinks,
    headerNavLinks,
    socialLinks,
    appProviders,
    paymentProviders,
    languages,
    affiliatesBlock,
    userNavLinks,
    config,
    appStaticPath,
  ] = await Promise.all([
    footerNavLinksPromise,
    headerNavLinksPromise,
    socialLinksPromise,
    appProvidersPromise,
    paymentProvidersPromise,
    languagesPromise,
    affiliatesBlockPromise,
    userNavLinksPromise,
    configPromise,
    staticRoutePathsPromise,
  ]);

  return {
    footerNavLinks,
    headerNavLinks,
    socialLinks,
    appProviders,
    paymentProviders,
    languages,
    affiliatesBlock,
    userNavLinks,
    config,
    appStaticPath: getStaticRoutePaths(appStaticPath, context?.locale),
  };
};
